

.dropdown-content{
    flex-grow: 1;
    .location-input{
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 0.8rem 0 0.8rem 1rem;
        width: 100%;

        .text{
            display: flex;
            flex-direction: column;

            width: 100%;
            margin: 0;
            .placeholder{
                width: 100%;
            }
        }


        .close-arrow{
            display: none;
        }

        .close-arrow.show{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            aspect-ratio: 1/1;
            border-radius: 50%;

            margin-right: 1rem;

            .icon{
                width: 20px;
                height: 20px;
            }
        }

        .close-arrow:hover{
            background-color: var(--icon-secundary-color);
        }
    }

    .dropdown-menu{
        display: none;
    }

    .dropdown-menu.show {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 500px;
        position: absolute;
        top: 0;
        left: 0;

        overflow: auto;

        margin-top: 4.1rem;

        border-radius: 6px;
        background-color: var(--secundary-color);

        .dropdown-menu-content {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            padding: 16px 0 16px 0;


            .dropdown-menu-header {
                display: flex;
                justify-content: space-between;

                font-family: var(--dropdown-menu-font-family);
                font-size: 10px;
                color: var(--dropdown-menu-color);
                padding: 0 16px 0 16px;
            }

            .dropdown-menu-options {
                display: flex;
                flex-direction: column;

                font-family: var(--dropdown-menu-font-family);
                font-size: 13px;
                color: white;

                .dropdown-menu-option:hover {
                    background-color: var(--hover-dropdown-option-color);
                }

                .hovered {
                    background-color: var(--hover-dropdown-option-color);
                }

            }

            .dropdown-menu-option {
                display: flex;
                align-items: center;
                height: 35px;
                cursor: pointer;
                padding: 0 16px 0 16px;

                .dropdown-menu-option-content {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    width: 100%;
                }

            }
        }
    }
    /* custom scrollbar */
    ::-webkit-scrollbar {
        width: 20px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #8C8C8C;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
    }

}


