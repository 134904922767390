@font-face {
    font-family: "Reckless Neue Book";
    src: url("../../fonts/RecklessNeue-Book.ttf");
}
@font-face {
    font-family: "Reckless Neue Regular";
    src: url("../../fonts/RecklessNeue-Regular.ttf");
}
@font-face {
    font-family: "Avenir Next Bold";
    src: url("../../fonts/AvenirNextLTPro-Bold.otf");
}
@font-face {
    font-family: "Avenir Next Regular";
    src: url("../../fonts/AvenirNextLTPro-Regular.otf");
}
@font-face {
    font-family: "Poppins Medium";
    src: url("../../fonts/Poppins-Medium.ttf");
}
@font-face {
    font-family: "Poppins SemiBold";
    src: url("../../fonts/Poppins-SemiBold.ttf");
}
@font-face {
    font-family: "Poppins Light";
    src: url("../../fonts/Poppins-Light.ttf");
}
@font-face {
    font-family: "Poppins Bold";
    src: url("../../fonts/Poppins-Bold.ttf");
}

@font-face {
    font-family: "Poppins Regular";
    src: url("../../fonts/Poppins-Regular.ttf");
}

input[type="search"] {
    -webkit-appearance: none; /* Para navegadores basados en WebKit */
    -moz-appearance: none; /* Para Firefox */
    appearance: none; /* Para otros navegadores */
    border: none; /* Quitar borde */
    outline: none; /* Quitar contorno al hacer foco */
    background: transparent; /* Fondo transparente */
    padding: 0; /* Quitar padding */
    margin: 0; /* Quitar margin */
    font-size: 16px; /* Establecer un tamaño de fuente deseado */
    color: inherit; /* Heredar el color del texto del contenedor */
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

* {
    -webkit-tap-highlight-color: transparent;
}

:root{
    /* COLORS */
    --primary-color: #F52003;
    --secundary-color: #212121;
    --background-color: #f9f5f3;

    --hover-dropdown-option-color: #484848;

    --titles-color: black;
    --text-primary-color: white;
    --text-secundary-color: #8C8C8C;
    --text-terciary-color: #3E3E3E;

    --icon-primary-color: #ffffff;
    --icon-secundary-color: #676767;

    --border-color: #8C8C8C;
    --error-color: #e74c3c;
    --success-color: #27ae60;

    --search-bar-field-selected-color: red;

    --calendar-day--passed: grey ;
    --calendar-day--today: #11b647;
    --calendar-day--holiday: #F52003;
    --calendar-day--holiday-passed: #F4410077;
    --calendar-day--work: #FAFD5A;
    --calendar-day--day-off: #5ADFFD;
    --calendar-day--weekend: rgba(140,140,140,0.34);
    --calendar-day--basic: transparent;

    /* MEASUREMENTS */
    --search-bar-fields-column-gap: 0.2rem;

    /* CURSORS */
    --cursor-default: url('/src/static/icons/cursors/cursor_default.svg'), default;
    --cursor-pointer: url('/src/static/icons/cursors/cursor_pointer.svg'), pointer;
    --cursor-pointer-day-off: url('/src/static/icons/cursors/cursor-pointer-day-off.svg'), pointer;
    --cursor-pointer-holiday: url('/src/static/icons/cursors/cursor-pointer-holiday.svg'), pointer;
    --cursor-pointer-work: url('/src/static/icons/cursors/cursor-pointer-work.svg'), pointer;
    --cursor-pointer-weekend: url('/src/static/icons/cursors/cursor-pointer-weekend.svg'), pointer;

    /* BUTTONS SECTION */
    --buttons-section-height: 3rem;

    --dropdown-background-color: #323232;

    --button-color: white;
    --button-font-family: "Poppins SemiBold", "serif";

    --dropdown-menu-font-family: "Poppins Regular", "serif";
    --dropdown-menu-color: #848484;
}