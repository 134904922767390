

.titles-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 1rem;

    width: 100%;
    color: var(--titles-color);

    .title{
        font-family: "Poppins Bold", "serif";
        font-size: clamp(2rem, 2vw + 0.5rem, 3.2rem);
        margin: 0;
    }

    .description{
        font-family: "Poppins Medium", "serif";
        
        font-size: clamp(1rem, 2vw + 0.5rem, 1.9rem);
        font-weight: 500;
        letter-spacing: 0;
        text-align: left;
        margin: 0;
    }
}