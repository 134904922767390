

.search-bar-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 0.5rem;

    width: 100%;
    .search-bar-fields {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
        column-gap: var(--search-bar-fields-column-gap);
        row-gap: 0.2rem;

        width: 85%;
    }
}

.clicked{
    outline: var(--border-color) 2px solid;
    z-index: 5;
}