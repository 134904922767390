

.search-bar-field {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;

    align-items: center;
    row-gap: 0.25em;
    height: 3.9rem;

    border-radius: 0.3rem;
    background-color: var(--secundary-color);
    cursor: url('/src/static/icons/cursors/cursor_pointer.svg'), pointer;

    .search-bar-field-content {
        display: flex;
        height: 100%;
        width: 100%;
        column-gap: 0.4rem;
        border-radius: 0.3rem;

        .text{
            margin: 0.8rem 0 0.8rem 1rem;
            width: 100%;

            .title {
                margin: 0;
                color: var(--text-secundary-color);

                font-family: "Poppins Bold", serif;
                font-size: 0.8em;
            }
            .placeholder{
                color: var(--text-primary-color);
                font-family: "Poppins Medium", serif;
                font-size: 0.8em;

                background: transparent;
                -webkit-appearance: none;
                outline: none;
                border: none;
                appearance: none;
                padding: 0;
            }

            .number-field-placeholder{
                min-width: 160px;
            }

            .placeholder::-webkit-inner-spin-button, .placeholder::-webkit-outer-spin-button{
                -webkit-appearance: none;
            }
            
            ::placeholder {
                color: var(--text-primary-color);
            }
        }
    }

    .divisor {
        width: 2px;
        height: 30px;
        background-color: white;

        -webkit-transition: .4s;
        transition: .4s;
    }
}

.number-field{
    min-width: 200px;
    max-width: 400px;
}

.dates-field{
    border: none;
    background-color: transparent;
    cursor: default;
    position: relative;
}

.dropdowns-field{

    min-width: 325px;
    position: relative;
}
