

.circle-button{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    border-radius: 50%;
    cursor: url('/src/static/icons/cursors/cursor_pointer.svg'), pointer;
    height: 100%;
    aspect-ratio: 1 / 1;

    background-color: #323232;
    border: none;
    padding: 0;
}