

.date-range-picker{
    display: none;
}

.date-range-picker.show{
    display: flex;
    flex-direction: column;

    border: black solid 1px;
    border-radius: 6px;

    position: absolute;
    left: 0;
    margin-top: 4.1rem;

    background-color: var(--secundary-color);
    z-index: 50;

    .months{
        display: flex;
        column-gap: 50px;
        margin: auto 25px;

        .date-range-picker-card{
            flex-basis: 19%;
            height: 25rem;

            display: flex;
            align-items: center;
            justify-content: center;

            .month-calendar{
                display: flex;
                flex-direction: column;
                row-gap: 25px;
                .month-header{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    font-family: "Avenir Next Bold","Roboto","Helvetica","Arial",sans-serif;
                    font-size: 1.2rem;
                    color: var(--text-primary-color);

                    .month-title{
                        margin: 0 auto;
                    }

                    .arrow-icon{
                        fill: var(--icon-primary-color);
                        cursor: pointer;
                    }

                    .not-allowed{
                        cursor: not-allowed;
                        fill: rgba(255, 255, 255, 0.5);
                    }
                }

                .calendar-grid{
                    display: grid;
                    grid-template-columns: repeat(7, 40px);
                    grid-template-rows: repeat(7, 40px);
                    justify-items: center;
                    align-items: center;
                    font-family: "Avenir Next Regular","Roboto","Helvetica","Arial",sans-serif;
                    font-weight: 400;
                    font-size: 0.70rem;
                    line-height: 1.66;

                    row-gap: 5px;

                    .calendar-weekday{
                        color: var(--text-primary-color);
                    }


                    .calendar-day{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        border-radius: 30px;

                        cursor: pointer;
                        color: var(--text-primary-color);

                        .day-number{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            width: 100%;
                            height: 100%;
                            align-content: center;
                            text-align: center;

                        }
                    }

                    .calendar-day p:hover{
                        outline: 1px solid black;
                        margin: 0;
                    }

                    /* DAYS STYLES */

                    .start-date, .end-date{
                        color: white;

                        .day-number{
                            background-color: black;
                        }
                    }

                    .start-date.in-range{
                        border-top-right-radius: 0 ;
                        border-bottom-right-radius: 0 ;
                        background-color: rgba(128, 128, 128, 0.2);
                    }

                    .end-date.in-range{
                        border-top-left-radius: 0 ;
                        border-bottom-left-radius: 0 ;
                        background-color: rgba(128, 128, 128, 0.2);
                    }

                    .between{
                        background-color: rgba(128, 128, 128, 0.2);
                        border-radius: 0;
                    }

                    .between.left-column{
                        border-top-left-radius: 5px ;
                        border-bottom-left-radius: 5px ;
                    }

                    .between.right-column{
                        border-top-right-radius: 5px ;
                        border-bottom-right-radius: 5px ;
                    }

                    .passed{
                        background-color: transparent;
                        color: grey;

                        p:hover{
                            outline: 0;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .calendar-position-left{
            .right-arrow{
                display: none;
            }
        }

        .calendar-position-right{
            .left-arrow{
                display: none;
            }
        }
    }

    .year-button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7rem;
        height: 2rem;
        border: 2px solid white;
        background-color: transparent;
        border-radius: 50px;
        color: white;

        cursor: pointer;
        margin: 0 0 25px 25px;
        .title{
            font-family: var(--dropdown-menu-font-family);
            font-size: 12px;
        }
    }

    .year-button:hover{

        background-color: white;
        color: black;
    }
}

/* SMALL SCREENS - MOBILES */
@media (max-width: 576PX) {

    .top{
        margin: 20px;
    }

    .search-bar-fields{
        position: static
    }

    .dates-field{
        position: relative;
    }


    .date-range-picker.show{

        position: absolute;
        flex-wrap: wrap;
        width: 100%;
        .months{

            .calendar-position-left{
                .arrow-icon{
                    display: block;
                }
            }

            .calendar-position-right{
                display: none;
            }
        }

    }

    .dropdown-content{
        .dropdown-menu.show{
            max-height: 300px;
        }
    }
}





/* MEDIUM SCREENS - TABLETS */
@media (576px <=  width <= 768px) {

    .search-bar-fields{
        position: relative;

        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
        z-index: 100;
    }

    .dates-field{
        position: relative;
    }

    .date-range-picker.show{
        position: fixed;
        width: 100%;
    }
}
