


.dates-field{
    display: flex;

    flex-grow: 1;
    flex-basis: 0;
    min-width: 325px;
    min-height: 3.9rem;

    .dates-field-content{

        column-gap: var(--search-bar-fields-column-gap);

        .from, .to {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            min-width: 75px;
            background-color: var(--secundary-color);
            border-radius: 0.3rem;

            cursor: pointer;

            .close-arrow{
                display: none;
            }

            .close-arrow.show{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                margin-right: 20px;

                .icon{
                    width: 20px;
                    height: 20px;
                }
            }

            .close-arrow:hover{
                background-color: var(--icon-secundary-color);
            }
        }

        .arrow-dates {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
        }
    }
}

