


/* GENERAL SECTION */
body{
    background-color:  var(--background-color);
    cursor: var(--cursor-default)
}

body::-webkit-scrollbar {
    display: none;
}

p{
    margin: 0;
}

/* HOME STYLES */
.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    row-gap: 2.7rem;
}

.top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1.6rem;
    width: 93.5rem;
    max-width: 90%;

    margin-top: 5rem;
}

.calendar{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    column-gap: 1rem;
    row-gap: 1.1rem;

    max-width: 90%;
    z-index: 0;

    .calendar-child{
        border: black solid 1px;
        border-radius: 5px;
        flex-basis: 19%;
        height: 25rem;

        display: flex;
        align-items: center;
        justify-content: center;

        .month-calendar{
            display: flex;
            flex-direction: column;
            row-gap: 25px;
            padding: 25px;

            .month-header{
                font-family: "Avenir Next Bold","Roboto","Helvetica","Arial",sans-serif;
                font-size: 1.2rem;
                color: var(--text-terciary-color);
            }

            .calendar-grid{
                display: grid;
                grid-template-columns: repeat(7, 40px);
                grid-template-rows: repeat(7, 40px);
                justify-items: center;
                align-items: center;
                gap: 5px;

                font-family: "Avenir Next Regular","Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 0.70rem;
                line-height: 1.66;

                .calendar-day{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;

                }
            }
        }
    }
}

/* DAY STYLES */
/* cursors */
.paint-mode-day_off{
    cursor: var(--cursor-pointer-day-off);
}
.paint-mode-holiday{
    cursor: var(--cursor-pointer-holiday);
}
.paint-mode-weekend{
    cursor: var(--cursor-pointer-weekend);
}
.paint-mode-work{
    cursor: var(--cursor-pointer-work);
}

.paint-mode-basic:not(.basic){
    cursor: var(--cursor-pointer);
}

/* circles */
.today{
    background-color: var(--calendar-day--today);
    color: white;
    cursor: var(--cursor-default);
}

.holiday-passed{
    background-color: var(--calendar-day--holiday-passed);
    color: white;
}

.work{
    fill: var(--calendar-day--work);
}
.calendar-day.work{
    background-color: var(--calendar-day--work);
    color: black;
    border-radius: 50%;
}

.holiday{
    fill: var(--calendar-day--holiday);
}

.calendar-day.holiday{
    background-color: var(--calendar-day--holiday);
    color: white;
    border-radius: 50%;
}

.weekend{
    fill: var(--calendar-day--weekend);
}

.calendar-day.weekend{
    background-color: var(--calendar-day--weekend);
    color: white;
    border-radius: 50%;
}

.day-off, .day_off{
    fill: var(--calendar-day--day-off);
}

.calendar-day.day-off{
    background-color: var(--calendar-day--day-off);
    color: black;
    border-radius: 50%;
}

.passed{
    background-color: transparent;
    color: var(--calendar-day--passed);
    cursor: var(--cursor-default);
}

.basic:hover:not(.paint-mode-default, .paint-mode-basic, .paint-mode-color, .advanced-mode-icon){
    background-color: #c9c6c6;
}




