


.advanced-mode{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 250px;
    height: 50px;

    border-radius: 50px;

    border: 1px solid var(--border-color);
    background-color: var(--secundary-color);
    bottom: 20px;


    .advanced-mode-content {
        display: flex;
        justify-content: space-between;
        width: 80%;

        .option{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .advanced-mode-icon{
                cursor: pointer;
                transition: transform 0.2s ease;
            }
            .advanced-mode-icon.default{
                fill: #cecece;
            }
            .advanced-mode-icon.clicked{
                outline: 0;
                border: 0;
                fill: var(--icon-primary-color);
            }
            .advanced-mode-icon.default:hover{
                fill: var(--icon-primary-color);
            }
            .advanced-mode-icon:hover{
                transform: scale(1.2)
            }

            .hover-info{
                display: none;
            }
            .hover-info.show{
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -210%;
                width: 80px;
                height: 30px;

                border-radius: 5px;
                background-color: black;

                .title{
                    font-family: var(--dropdown-menu-font-family);
                    font-size: 10px;
                    color: var(--text-primary-color);
                }
            }

        }
    }

    .dropdown-menu{
        display: none;
    }

    .dropdown-menu.show{
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        background-color: var(--secundary-color);
        margin-top: 8px;

        width: 100%;
        position: absolute;
        bottom: 60px;

        z-index: 100;

        .dropdown-menu-content{
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            padding: 16px 0 16px 0;


            .dropdown-menu-header{
                display: flex;
                justify-content: space-between;

                font-family: var(--dropdown-menu-font-family);
                font-size: 10px;
                color: var(--text-secundary-color);
                padding: 0 16px 0 16px;
            }

            .dropdown-menu-options{
                display: flex;
                flex-direction: column;

                font-family: var(--dropdown-menu-font-family);
                font-size: 13px;
                color: var(--text-primary-color);

                .dropdown-menu-option:hover:not([id^="weekend-days"]){
                    background-color: var(--hover-dropdown-option-color);
                }

                .dropdown-menu-option[id^="weekend-days"] {
                    .dropdown-menu-option-content{
                        flex-direction: row-reverse;
                        justify-content: space-between;
                        cursor: default;
                    }
                }
            }

            .dropdown-menu-option{
                display: flex;
                align-items: center;
                height: 35px;
                cursor: pointer;
                padding: 0 16px 0 16px;
                .dropdown-menu-option-content{
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    width: 100%;
                }

            }
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}