

.switch-button{
    display: flex;
    justify-content: space-between;
    height: 20px;
    aspect-ratio: 1.7;

    .switch-button-input {
        appearance: none;
        background-color: #D9D9D9;
        border-radius: 72px;
        border-style: none;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        position: relative;
        cursor: pointer;
    }

    .switch-button-input, .switch-button-input::after {
        transition: all 100ms ease-out;
    }

    .switch-button-input::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        height: calc(100% - 6px);
        aspect-ratio: 1/1;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;

    }

    .switch-button-input:hover {
        background-color: #76797c;
        transition-duration: 0s;
    }

    .switch-button-input:checked {
        background-color: #F52003;
    }

    .switch-button-input:checked::after {
        background-color: #fff;
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }

    :focus:not(.focus-visible) {
        outline: 0;
    }

    .switch-button-input:checked:hover {
        background-color: #c91801;
    }
}

/*
.switch-button{
    display: flex;
    height: 25px;
    aspect-ratio: 2;

    input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;
    }

    label:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;

        height: calc(100% - 10px);
        aspect-ratio: 1/1;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

    input:checked + label {
        background: #D71F07;
    }

    input:checked + label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }

}

 */




