

.optimize-button{
    display: flex;
    flex-grow: 1;
    flex-basis: 150px;
    height: 3.9rem;
    align-items: center;
    justify-content: center;
    column-gap: 9px;
    cursor: url('/src/static/icons/cursors/cursor_pointer.svg'), pointer;

    background-color: var(--primary-color);

    border: none;
    border-radius: 0.31rem;

    font-family: "Poppins Bold", "serif";
    font-size: 0.9em;

    .title{
        color: var(--text-primary-color)
    }

}